import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Droid Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }
  html, body, #___gatsby, #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
  }
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.6rem;
    height: 100%;
    background: ${props => props.theme.background};
    color: ${props => props.theme.color};
  }
`;
