import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import * as themes from '../../styles/themes';
import GlobalStyle from '../../styles/global';
import ThemeContext from '../../styles/themes/context';

import usePersitedState from '../../hooks/usePersistedState';

function Layout({ children }) {
  const [theme, setTheme] = usePersitedState('theme', 'light');

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeProvider theme={themes[theme]}>
      <ThemeContext.Provider value={{ toggleTheme: toggleTheme }}>
        {children}
      </ThemeContext.Provider>
      <GlobalStyle />
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
